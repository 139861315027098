import type { DateSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import Typography from '@mui/material/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { formatDateRange } from '../util';


export const Slice_Date = (p: SliceProps<DateSlice>): JSX.Element => {
	return (
		<section>
			<Typography component="h2" variant="h2" fontWeight="bold">
				{ p.slice.title }
			</Typography>
			{ p.slice.subtitle && (
				<Typography fontWeight="bold">
					{ p.slice.subtitle }
				</Typography>
			)}

			<Typography variant="body2" display="flex" alignItems="center" gap={.5}>
				<CalendarMonthIcon/>
				{ formatDateRange(p.slice.startDate, p.slice.endDate) }
			</Typography>
		</section>
	);
};
