import type { ExternalLinkSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';


export const Slice_ExternalLink = (p: SliceProps<ExternalLinkSlice>): JSX.Element => {
	return (
		<section>
			<Alert severity="info" icon={<InfoOutlineIcon  fontSize="large"/>} sx={{ flex: 1 }}>
				<AlertTitle>
					{ p.slice.title }
				</AlertTitle>
				<Button
					className="link"
					variant="contained"
					endIcon={<LaunchIcon/>}
					href={p.slice.link}
					target="_blank"
				>
					{ p.slice.linkText }
				</Button>
			</Alert>
		</section>
	);
};
