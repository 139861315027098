import { format, isSameMonth } from 'date-fns';


export const formatDateRange = (startDate: string, endDate: string): string => {
	const start = new Date(startDate);
	if(startDate === endDate) {
		return format(start, 'do LLLL y');
	}

	const end = new Date(endDate);

	if(isSameMonth(start, end)) {
		return format(start, 'do') + ' - ' + format(end, 'do LLLL y');
	}

	return format(start, 'do LLLL y') + ' - ' + format(start, 'do LLLL y');
};
