import type { LocationSlice } from '../../domain';
import type { SliceProps } from './SliceProps';
import { css, styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';


const makeStaticMapUrl = (latitude: number, longitude: number): string => {
	const url = new URL('https://maps.googleapis.com/maps/api/staticmap');
	const center = `${latitude},${longitude}`;
	url.searchParams.set('center', center);
	url.searchParams.set('zoom', '9');
	url.searchParams.set('scale', '2');
	url.searchParams.set('size', `600x${Math.ceil(600*.56)}`);
	url.searchParams.set('markers', `|${center}`);

	url.searchParams.set('key', 'AIzaSyCLzWQo7E3q2vdVCdT_HmNsrXP5EiVQ29o');

	return url.href;
};

const makeDynamicMapUrl = (latitude: number, longitude: number): string => {
	const url = new URL('https://maps.google.com/');
	url.searchParams.set('q', latitude + ',' + longitude);

	return url.href;
};

const MapButton = styled(ButtonBase)(({ theme }) => css`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56%;
	border-radius: ${theme.shape.borderRadius}px;
	overflow: hidden;
	
	margin-top: ${theme.spacing(1)};

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	&:hover {
		filter: brightness(105%) saturate(110%);
	}
	transition: ${theme.transitions.create([ 'filter' ], {
		duration: theme.transitions.duration.standard,
	})};
`) as typeof ButtonBase;

const Slice_LocationRoot = styled('section')(({ theme }) => css`
	--sliceSpacing: ${theme.spacing(1)};
`);

export const Slice_Location = (p: SliceProps<LocationSlice>): JSX.Element => {
	return (
		<Slice_LocationRoot>
			<Typography variant="h6" component="h3" fontWeight="bold">
				{ p.slice.title}
			</Typography>
			{ p.slice.subtitle && (
				<Typography variant="body2">
					{ p.slice.subtitle }
				</Typography>
			)}
			<MapButton
				href={makeDynamicMapUrl(p.slice.latitude, p.slice.longitude)}
				target="_blank"
				focusRipple
			>
				<img src={makeStaticMapUrl(p.slice.latitude, p.slice.longitude)}/>
			</MapButton>
		</Slice_LocationRoot>
	);
};
