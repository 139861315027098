/*
	Type definitions for Broadside-API data.
	Generated at 2025-04-02T15:26:41.518Z.
*/
/* eslint-disable */

///////////////////////////////////////////////////////////////////////////////
// api-data.ts
///////////////////////////////////////////////////////////////////////////////
export type YachtData = {
	type: "yacht";
	data: Yacht | null;
};
export type ArticleData = {
	type: "article";
	data: Article | null;
};
export type NavData = {
	headerLinks: HeaderLinks;
	footerLinks: FooterLinks;
};
export type UriData = (YachtData | ArticleData) & NavData;
export type Redirect = {
	type: "redirect";
	destination: string;
	permanent: boolean;
};
export type YachtsData = {
	type: "yachts";
	data: {
		yachts: YachtLimited[];
		meta: {
			totalCount: number;
			offset: number;
			limit: number;
		};
	};
};
export type SuggestionsData = {
	type: "suggestions";
	data: {
		results: SuggestionResult[];
		meta: {
			q: string;
			limit: number;
		}
	};
};
export namespace SuggestionResult {
	type Common = {
		id: string;
		name: string;
		image?: string;
	};
	export type Location = Common & {
		type: "location";
		place?: boolean;
		area?: string;
		subArea?: string;
		yachtCount: number;
	};
	export type Builder = Common & {
		type: "builder";
		yachtCount: number;
	};
	export type Yacht = Common & {
		type: "yacht";
		uri: string;
		category: Category;
		builder: Enumeration;
		yearLaunch: number;
		yearRefit?: number;
		lengthMeters: number;
	};
	export type Article = Common & {
		type: "article";
		tagline?: string;
		uri: string;
	};
}
export type SuggestionResult = (SuggestionResult.Builder | SuggestionResult.Location | SuggestionResult.Yacht | SuggestionResult.Article);
export type SuggestionType = SuggestionResult["type"];
export type SitemapData = {
	type: "sitemap";
	data: {
		pages: SitemapPage[];
		meta: {
			totalCount: number;
			offset: number;
			limit: number;
		}
	};
};


///////////////////////////////////////////////////////////////////////////////
// article.ts
///////////////////////////////////////////////////////////////////////////////
export type HeroActionMode = "search" | "enquire";
export type Article = Page & {
	title: string;
	metaTitle: string;
	shortTitle: string;
	tagline?: string;
	video?: Video;
	heroAction: HeroActionMode;
	place?: string;
	placeId?: string;
	featuredYacht?: YachtLink;
};


///////////////////////////////////////////////////////////////////////////////
// article-limited.ts
///////////////////////////////////////////////////////////////////////////////
/** Type of yacht included in results from /search. */
export type ArticleLimited = Pick<Article, (
	| "id"
	| "title"
	| "tagline"
	| "image"
)> & {
	uri: string;
	uris?: Record<Language, string>;
	description: string;
};


///////////////////////////////////////////////////////////////////////////////
// crew-profile.ts
///////////////////////////////////////////////////////////////////////////////
export type CrewProfile = {
	image?: string;
	name: string;
	role: string;
	description: string;
};


///////////////////////////////////////////////////////////////////////////////
// currency.ts
///////////////////////////////////////////////////////////////////////////////
export const currencies = [
	"GBP",
	"EUR",
	"USD",
	"AUD",
	"NZD",
] as const;
export type Currency = typeof currencies[number];
export const defaultCurrency: Currency = "USD";
export const isCurrency = (x: unknown): x is Currency => {
	switch(x) {
		case "GBP":
		case "EUR":
		case "USD":
		case "AUD":
		case "NZD":
			return true;
		default:
			return false;
	}
};
export const asCurrency = (x: unknown): Currency => isCurrency(x) ? x : defaultCurrency;


///////////////////////////////////////////////////////////////////////////////
// enquiry.ts
///////////////////////////////////////////////////////////////////////////////
export type EnquiryYachtType = "Sailing" | "Motor";
export type Enquiry = {
	yachtId?: string;
	name: string;
	country: string;
	telephone: string;
	email: string;
	startDate?: string;
	endDate?: string;
	season?: string;
	guests?: number;
	location?: string;
	locationId?: string;
	yachtType?: EnquiryYachtType;
	budget?: string;
	message?: string;
	terms: boolean;
	marketing: boolean;
	timezone: string;
	campaignCode?: string;
	locale?: Language;
	currency?: Currency;
	gclid?: string;
};
export namespace EnquiryResult {
	export type FailureReason = (
		| "accept-terms"
		| "email-invalid"
		| "phone-invalid"
	);
	export type Failure = {
		ok: false;
		reasons: FailureReason[];
		emailSuggestion?: string;
	};
	export type Success = {
		ok: true;
	};
}
export type EnquiryResult = EnquiryResult.Failure | EnquiryResult.Success;


///////////////////////////////////////////////////////////////////////////////
// enumeration.ts
///////////////////////////////////////////////////////////////////////////////
export type Enumeration = {
	id: string;
	name: string;
};
export type Category = {
	id: Category.Id,
	name: string
};
export namespace Category {
	export type Id = "SAIL" | "POWER";
	export const ids:Id[] = [ "SAIL", "POWER" ];
}
export const isCategoryId = (x: unknown): x is Category.Id => {
	switch(x) {
		case "SAIL":
		case "POWER":
			return true;
		default:
			return false;
	}
};
export const asCategoryId = (x: unknown): Category.Id | undefined => (
	isCategoryId(x) ? x : undefined
);
export type Hull = {
	id: Hull.Id,
	name: string
};
export namespace Hull {
	export type Id = "MONOHULL" | "MULTIHULL";
	export const ids:Id[] = [ "MONOHULL", "MULTIHULL" ];
}
export const isHullId = (x: unknown): x is Hull.Id => {
	switch(x) {
		case "MONOHULL":
		case "MULTIHULL":
			return true;
		default:
			return false;
	}
};


///////////////////////////////////////////////////////////////////////////////
// nav-link.ts
///////////////////////////////////////////////////////////////////////////////
export type NavLink = {
	title: string;
	uri: string;
};
export type FooterLinks = {
	pages: NavLink[];
	legal: NavLink[];
};
export type HeaderLinks = NavLink[];


///////////////////////////////////////////////////////////////////////////////
// image.ts
///////////////////////////////////////////////////////////////////////////////
export type Image = {
    id: string;
    caption: string;
    width: number;
    height: number;
};


///////////////////////////////////////////////////////////////////////////////
// languages.ts
///////////////////////////////////////////////////////////////////////////////
export const languages = [
	"en",
	"en-GB",
	"fr",
	"de",
	"es",
	"pt",
	"it",
	"ru",
	"ja",
	"zh",
] as const;
export type Language = typeof languages[number];
export const defaultLanguage: Language = "en";
export const isLanguage = (x: unknown): x is Language => {
	switch(x) {
		case "de":
		case "en":
		case "en-GB":
		case "es":
		case "fr":
		case "it":
		case "ja":
		case "pt":
		case "ru":
		case "zh":
			return true;
		default:
			return false;
	}
};
export const asLanguage = (x: unknown): Language => isLanguage(x) ? x : defaultLanguage;


///////////////////////////////////////////////////////////////////////////////
// page.ts
///////////////////////////////////////////////////////////////////////////////
export type Page = {
	id: string;
	noCache: boolean;
	metaDescription: string;
	metaImages?: string[];
	uris: Record<Language, string>;
	datePublished: string;
	dateModified: string;
	footerLinks: FooterLinks;
	image: string;
	slices: Slice[];
	headSlices?: Slice[];
	sideSlices?: Slice[];
	tailSlices?: Slice[];
	relatedYachts?: RelatedYachts[];
	campaignCode?: string;
};
export type SitemapPage = Pick<Page, "id" | "uris" | "datePublished" | "dateModified">;


///////////////////////////////////////////////////////////////////////////////
// related-yachts.ts
///////////////////////////////////////////////////////////////////////////////
export type RelatedYachts = {
	title: "popular-yachts" | "latest-yachts" | "popular-nearby-yachts" | "latest-nearby-yachts" | "popular-related-yachts";
	relatedYachts: {
		id: string;
		subTitle: string;
		yachts: YachtLimited[];
	}[]
};


///////////////////////////////////////////////////////////////////////////////
// slice.ts
///////////////////////////////////////////////////////////////////////////////
export type MarkdownSlice = {
	id: string;
	contentType: "TEXT";
	markdown: string;
};
export type ImagesSlice = {
	id: string;
	title?: string;
	contentType: "IMAGES";
	images: Image[];
};
export type YachtsSlice = {
	id: string;
	title?: string;
	contentType: "YACHTS";
	yachts: YachtLimited[];
};
export type YachtsByTypeSlice = {
	id: string;
	title?: string;
	contentType: "YACHTS_BY_TYPE";
	place?: string;
};
export type ArticlesSlice = {
	id: string;
	title?: string;
	contentType: "ARTICLES";
	articles: ArticleLimited[];
};
export type MediaCarouselSlice = {
	id: string;
	contentType: "MEDIA_CAROUSEL";
	video?: Video;
	images: Image[];
};
export type YachtFeaturesSlice = {
	id: string;
	contentType: "YACHT_FEATURES";
	amenities?: Enumeration[];
	toys?: Enumeration[];
	diving?: Enumeration[];
	tenders?: Enumeration[];
};
export type YachtLayoutSlice = {
	id: string;
	contentType: "YACHT_LAYOUT";
	layoutImage: Image;
};
export type CrewProfileSlice = {
	id: string;
	contentType: "CREW_PROFILE";
	image?: string;
	profiles?: CrewProfile[];
};
export namespace YachtSpecificationSlice {
	export type Specification = {
		name: string;
		priorName?: string;
		lengthMeters: number;
		beamMeters?: number;
		draftMeters?: number;
		speedCruiseKPH?: number;
		speedMaxKPH?: number;
		fuelCruiseLPH?: number;
		fuelMaxLPH?: number;
		rangeCruiseKm?: number;
		rangeMaxKm?: number;
		engine?: string;
		hull: Hull;
		stabilisers: Enumeration;
		flagCountry?: Enumeration; // Could show a flag SVG, like in Spinnaker. ID should be CODE
		yearLaunch: number;
		yearRefit?: number;
		builder: Enumeration;
		designer?: string;
	};
	export const keys: Readonly<(keyof YachtSpecificationSlice.Specification)[]> = [
		"name",
		"priorName",
		"lengthMeters",
		"beamMeters",
		"draftMeters",
		"speedCruiseKPH",
		"speedMaxKPH",
		"fuelCruiseLPH",
		"fuelMaxLPH",
		"rangeCruiseKm",
		"rangeMaxKm",
		"engine",
		"hull",
		"stabilisers",
		"flagCountry",
		"yearLaunch",
		"yearRefit",
		"builder",
		"designer",
	] as const;
	export type Key = typeof keys[number];
}
export type YachtSpecificationSlice = {
	id: string;
	contentType: "YACHT_SPECIFICATION";
	specification: YachtSpecificationSlice.Specification;
};
export type FAQSlice = {
	id: string;
	contentType: "FAQS";
	title: string;
	faqs: FAQ[];
};
export type FAQ = {
	title: string;
	markdown: string;
};
namespace TemplatedFAQModel {
	export type Yacht = {
		yachtName: string;
		priceWeek?: number;
		priceDay?: number;
		currency?: Currency;
		guestsSleeping: number;
		guestsCruising?: number;
		guestsEntertaining?: number;
		cabinCount: number;
		positionName?: string;
		positionTime?: string;
	};
}
type TemplatedFAQModel = TemplatedFAQModel.Yacht;
type TemplatedFAQ = {
	questionKey: string;
	answerKey: string;
};
export type TemplatedFAQSlice = {
	id: string;
	contentType: "TEMPLATED_FAQS"
	titleKey: string;
	model: TemplatedFAQModel;
	faqs: TemplatedFAQ[]
};
export type YachtSearchSlice = {
	id: string;
	contentType: "YACHT_SEARCH";
	title: string;
	subtitle: string;
	filter: YachtSearchFilter;
};
export type InquiryFormSlice = {
	id: string;
	contentType: "INQUIRY_FORM" | "INQUIRY_FORM_LITE";
	markdown?: string;
};
/** Slices with no content, just a marker to be handled in the UI. */
export type TagSlice = {
	id: string;
	contentType: "YACHT_LEGAL";
};
export type YachtTitleSlice = {
	id: string;
	contentType: "YACHT_TITLE",
	title: string | null
};
export namespace YachtRatesSlice {
	export type RateType = "low" | "high" | "only";
	export type Rate = {
		id: string;
		rateType: YachtRatesSlice.RateType;
		/** Will be `null` for `inquire` rates. */
		amount: number | null;
		currency: Currency;
		baseAmount: number | null;
		baseCurrency: Currency;
		expense: Enumeration;
	};
	export type SubArea = Enumeration & {
		image: string;
		uri: string;
		uris: Record<Language, string>;
	};
	export type Area = Enumeration & {
		image: string;
		subAreas: SubArea[];
		season: "summer" | "winter" | null;
		rates: YachtRatesSlice.Rate[];
		uri: string;
		uris: Record<Language, string>;
	};
}
export type YachtRatesSlice = {
	id: string;
	contentType: "YACHT_RATES";
	title: string;
	areas: YachtRatesSlice.Area[];
};
export type DateSlice = {
	id: string;
	title: string;
	subtitle?: string;
	contentType: "DATE";
	startDate: string;
	endDate: string;
};
export type LocationSlice = {
	id: string;
	contentType: "LOCATION",
	title: string;
	subtitle?: string;
	latitude: number;
	longitude: number;
};
export type ExternalLinkSlice = {
	id: string;
	contentType: "EXTERNAL_LINK";
	title: string;
	linkText: string;
	link: string;
};
export type Slice = (
	| MarkdownSlice
	| ImagesSlice
	| YachtsSlice
	| YachtsByTypeSlice
	| ArticlesSlice
	| MediaCarouselSlice
	| YachtFeaturesSlice
	| YachtLayoutSlice
	| YachtSpecificationSlice
	| CrewProfileSlice
	| FAQSlice
	| TemplatedFAQSlice
	| YachtSearchSlice
	| InquiryFormSlice
	| TagSlice
	| YachtTitleSlice
	| YachtRatesSlice
	| DateSlice
	| LocationSlice
	| ExternalLinkSlice
);
export type SliceContentType = Slice["contentType"];
type SliceOfType<T extends SliceContentType> = Slice & { contentType: T };
/** Type-guard for Slices by contentType. */
export const isSlice = <T extends SliceContentType>(slice: Slice, contentType: T): slice is SliceOfType<T> => {
	return slice.contentType === contentType;
};
/**
 * Returns a type-guard predicate suitable for use in `Array.filter()`.
 *
 * @example slices
 * 	  .filter(filterSlice('TEXT'))
 * 	  .forEach(slice => console.log(slice.markdown));
 */
export const filterSlice = <T extends Slice["contentType"]>(contentType: T) => (
	(slice: Slice): slice is SliceOfType<T> => isSlice(slice, contentType)
);


///////////////////////////////////////////////////////////////////////////////
// video.ts
///////////////////////////////////////////////////////////////////////////////
export type Video = {
	id: string;
	title: string;
	src: string;
	url: string;
	image: string;
};


///////////////////////////////////////////////////////////////////////////////
// yacht.ts
///////////////////////////////////////////////////////////////////////////////
export type Yacht = Page & {
	featured: boolean;
	lowPrice?: number;
	basePrice?: number;
	baseCurrency?: Currency;
	name: string;
	active: boolean;
	lengthMeters: number;
	yearLaunch: number;
	yearRefit?: number;
	category: Category;
	builder: Enumeration;
	guestsSleeping: number;
	accommodationDetails: Enumeration[]; // Could show better cabin info, like in Spinnaker. TODO Let's make this cabins
	cabinCount: number;
	crewSize: number;
};


///////////////////////////////////////////////////////////////////////////////
// yacht-limited.ts
///////////////////////////////////////////////////////////////////////////////
/** Type of yacht included in results from /search. */
export type YachtLimited = Pick<Yacht, (
	| "id"
	| "name"
	| "builder"
	| "image"
	| "lengthMeters"
	| "yearLaunch"
	| "yearRefit"
	| "guestsSleeping"
	| "lowPrice"
	| "basePrice"
	| "baseCurrency"
	| "featured"
)> & {
	uri: string;
	uris?: Record<Language, string>;
	video: boolean; // We only care if a video exists, in order to show an icon.
};


///////////////////////////////////////////////////////////////////////////////
// yacht-link.ts
///////////////////////////////////////////////////////////////////////////////
export type YachtLink = Pick<YachtLimited, "name" | "uris" | "uri">;


///////////////////////////////////////////////////////////////////////////////
// yacht-search-query.ts
///////////////////////////////////////////////////////////////////////////////
export type YachtSearchQuery = {
	filter: YachtSearchFilter;
	sort: string | string[], // '-' modifier reverses order. Sort by 'popularity' or field name such as 'lowPrice','guestSleeping','lengthMeters','name','yearLaunch'. e.g. '-lengthMeters,name' is length descending name ascending
	page: PageOffset;
};
export type YachtSearchFilter = {
	place?: string, // A location matching the result text from a search
	areas?: string[],
	subAreas?: string[],
	builder?: string,
	hull?: Hull.Id,
	category?: Category.Id,
	date?: Range<string>, // ISO 8601 Date e.g '2016-02-19'
	guestsSleeping?: Range<number>, // Number range
	lowPrice?: Range<number>, // Number range
	lengthMeters?: Range<number>; // Number range
	yearLaunch?: Range<number>; // Number range
};
export type Range<T> = T | [ min: T, max: T];
export type PageOffset = {
	offset: number;
	limit: number;
};
